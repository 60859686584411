import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Paper, Container, TableContainer } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { getDownloadURL, ref, deleteObject } from 'firebase/storage';
import { collection, query, where, getDocs, doc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { deleteObjectData } from '../Utilities/apiConnector';

function ObjectList({ onSelectFiles, category, type, id, refreshKey, collectionName }) {
  const { orgId } = useParams();

  const [files, setFiles] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = loadFiles();
    return () => unsubscribe();
  }, [category, id, refreshKey, selectedFiles]);

  const columns = [
    { field: 'fileName', headerName: 'Name', width: 200 },
    {
      field: 'fileURL',
      headerName: 'Download',
      width: 200,
      renderCell: (params) => (
        <a href={params.value}>Download</a>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={(e) => {
            e.stopPropagation(); // Prevent row selection when the button is clicked
            handleButtonClick(params.row);
          }}>
          Edit
        </Button>
      ),
    },
  ];



  const loadFiles = () => {
    if (!id) {
      console.error('Undefined ID');
      return;
    }

    const fileCollection = collection(db, collectionName);
    const fileQuery = query(fileCollection, where('owner', '==', id));

    const unsubscribe = onSnapshot(fileQuery, (querySnapshot) => {
      const filePromises = querySnapshot.docs.map(async (doc) => {
        const data = doc.data();
        const fileName = data.fileName.split('.')[0];
        const storageRef = ref(storage, `${category}/${id}/${collectionName}/${fileName}/${data.fileName}`);
        const url = await getDownloadURL(storageRef);

        return { id: doc.id, fileName: data.fileName, fileURL: url, status: data.status };
      });

      Promise.all(filePromises).then(fileList => {
        setFiles(fileList);
      });
    });

    // Clean up listener on unmount
    return unsubscribe;
  };


  const handleButtonClick = (row) => {
    navigate(`/organizations/${orgId}/objects/${row.id}`);
    // Handle your logic here
  };


  const handleDeleteClick = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {

    const promises = selectedFiles.map(async (file) => {
      const fileName = file.fileName.split('.')[0];
      const storageRef = ref(storage, `${category}/${id}/${collectionName}/${fileName}/${file.fileName}`);
      try {
        await deleteObject(storageRef);
        await deleteObjectData(file.id);
      } catch (error) {
        if (error.message.includes("object does not exist")) {
          console.log(`File ${file.fileName} does not exist, skipping deletion.`);
        } else {
          // If it's another error, rethrow it
          throw error;
        }
      }
    });

    await Promise.all(promises);  // Wait for all deletions to complete
    setOpenDeleteDialog(false);
    setSelectedFiles([]);
    onSelectFiles(selectedFiles);
    // loadFiles();  // Reload the files
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const handleSelectionChange = (newSelection) => {
    // Filter out the IDs of files with a 'pending' status
    const selectableIds = newSelection.filter((id) =>
      files.find((file) => file.id === id && file.status !== 'pending')
    );

    // Find the selected files based on the filtered IDs
    const selectedFiles = files.filter((file) => selectableIds.includes(file.id));

    setSelectedFiles(selectedFiles);
    onSelectFiles(selectedFiles); // Call the callback with selected files
  };

  return (
    <Paper>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" component="h1" sx={{ p: 2 }}>Objects List</Typography>
        {selectedFiles.length > 0 && (
          <>
            <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={handleDeleteClick}>Delete</Button>
          </>
        )}
      </Box>
      <DataGrid
        rows={files}
        columns={columns}
        checkboxSelection
        onRowSelectionModelChange={handleSelectionChange}
        rowSelectionModel={selectedFiles.map(file => file.id)}
        getRowId={(row) => row.id}
      />
      <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Delete File</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete these {selectedFiles.length} file(s)?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default ObjectList;
