import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, TextField, Paper, Dialog, DialogTitle, DialogContent, Typography, DialogActions, Divider, TableContainer, Container } from '@mui/material';
import { styled } from '@mui/system';
import ChatBubble from '../Components/ChatBubble';
import ChatInput from '../Components/ChatInput';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { subscribeMessages, getChat, getExpert, updateChat, getTags } from '../Utilities/firestoreConnector';
import { UserContext } from '../UserProvider';
import { getSpeech, clearMessages, deleteConversation, sendMessageToConversation, addMessageToConversation, addMessageToThread, getResource } from '../Utilities/apiConnector';
import ObjectDisplay from '../Components/ObjectDisplay';

import { eventManager } from '../Utilities/eventManager';
import { useError } from '../Components/contexts/ErrorContext';
import { useNotification } from '../Components/contexts/NotificationContext';

const ChatContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '85vh',
  padding: '16px',
});

const MessageContainer = styled(Box)({
  flex: '1 1 auto',
  overflowY: 'scroll',
  marginBottom: '16px',
});

const StyledButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export default function Chat() {
  const { user, userData, updateUser } = useContext(UserContext);
  const { chatId } = useParams();
  const audioRef = useRef(null);

  const [messages, setMessages] = useState([]);
  const [audioSrc, setAudioSrc] = useState(null);
  const [isMuted, setIsMuted] = useState(true);
  const [thinking, setThinking] = useState(false);
  const [chat, setChat] = useState(null);
  const [expert, setExpert] = useState('');
  const [chatName, setChatName] = useState('');

  const [object, setObject] = useState({});
  const [tagList, setTagList] = useState([]);
  const [variables, setVariables] = useState([]);
  const [visibleTags, setVisibleTags] = useState([]);
  const [meshData, setMeshData] = useState(null);
  const [currentSubmesh, setCurrentSubmesh] = useState(null);
  const [submeshes, setSubmeshes] = useState([]);

  const [open, setOpen] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');

  const [textStream, setTextStream] = useState('');

  const navigate = useNavigate();

  const messagesEndRef = useRef(null);

  const { showError, hasError } = useError();
  const { addNotification } = useNotification();

  useEffect(() => {
    const handleError = (error) => { showError(error); };
    eventManager.subscribe('api-error', handleError);
    return () => { eventManager.unsubscribe('api-error', handleError); };
  }, []);

  useEffect(() => {
    loadChat();
  }, []);

  const loadChat = async () => {
    const chat = await getChat(chatId);
    const expert = await getResource('expert', chat.expert);
    setChat(chat);
    setExpert(expert.data);
    setChatName(chat?.name || "New chat");
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const mute = () => {
    if (audioRef.current) {
      if (isMuted) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
    setIsMuted(!isMuted);
  };

  const sendMessage = async (message) => {

    setThinking(true);

    const expert = await getResource('expert', chat.expert);

    if (expert.data?.externalRef?.openai) {

      const role = 'user';
      const resp = await addMessageToThread(chatId, { message, role });
      // console.log(resp);

      console.log("--- onepai expert ---");

      setThinking(false);

      const ttsData = { text: resp[0].text.value, model: 'tts-1-hd', voice: 'alloy' }
      const url = await getSpeech(ttsData);
      setAudioSrc(url);
      return;
    }

    // console.log(message, chatId, expert);




    await addMessageToConversation(chatId, message, true);
    // const response = await sendMessageToConversation(chatId, message);
    setTextStream('');
    setVariables([]);
    let text = '';
    let messageData = '';
    const closeStream = sendMessageToConversation(
      chatId,
      message,
      (newMessage) => {
        if (isSummaryMessage(newMessage)) {
          messageData = newMessage;
        } else {

          setTextStream(prevDebugStream => {
            const updatedStream = prevDebugStream + newMessage;
            const cleanStream = catchVariables(updatedStream);
            text = cleanStream;
            // 
            return cleanStream;
          });
        }
      },
      async () => {

        if (hasError.current) return;

        await addMessageToConversation(chatId, text, false, messageData);
        setTextStream('');

        setThinking(false);

        if (hasError.current) return;

        const ttsData = { text: text, model: 'tts-1-hd', voice: 'alloy' }
        const url = await getSpeech(ttsData);
        setAudioSrc(url);
      }
    );

    function isSummaryMessage(message) { return typeof message === 'object' && message.usage && message.log; }
  };

  const catchVariables = (text) => {
    const found = text.match(/{{(.*?)}}/g)?.map(variable => variable.replace(/{{|}}/g, '')) || [];

    if (found.length > 0) {
      setVariables(prevArray => {
        const combinedArray = [...prevArray, ...found];
        return combinedArray.filter((item, index) => combinedArray.indexOf(item) === index);
      });
    }

    text = text.replace(/{{(.*?)}}/g, '');
    text = text.replace(/\[\s+\]/g, '');
    return text;
  };

  useEffect(() => {
    setVisibleTags(variables);

    if (variables.length > 0) {
      const tagFound = tagList.find(t => variables.includes(t.id));
      const submeshFound = submeshes.find(s => s?.name === tagFound?.submesh?.name);
      setCurrentSubmesh(submeshFound);
    }

  }, [variables]);


  const clearConversation = async () => {
    await clearMessages(chatId);
    setVisibleTags([]);
    setCurrentSubmesh(null);
  };

  const handleDeleteConversation = async () => {
    await deleteConversation(chatId);
    setVisibleTags([]);
    setCurrentSubmesh(null);
    navigate(`/chats`);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (chatId !== '') {
      const unsubscribe = subscribeMessages(chatId, setMessages);
      return () => unsubscribe();
    }
  }, [chatId]);

  useEffect(() => {
    if (audioSrc && audioRef.current && !isMuted) {
      audioRef.current.play();
    }
  }, [audioSrc]);

  useEffect(() => {
    const unsubscribe = loadObject();
    return () => unsubscribe();
  }, [expert]);


  const loadObject = () => {
    if (!expert?.resources?.objects || Object.keys(expert.resources.objects).length === 0) { return () => { }; }

    const objectId = expert.resources.objects[0];
    const fileDocRef = doc(db, 'objects', objectId);

    const fetchData = async () => {
      const tags = await getTags(objectId);
      setTagList(tags || []);
    };

    fetchData();

    const unsubscribe = onSnapshot(fileDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setObject(data);
      } else {
        console.error('Document does not exist');
      }
    });

    return unsubscribe;
  };


  const handleNameChange = async (name) => {
    setChatName(name);
    await updateChat(chatId, { ...chat, name });
  };

  const handleInfoShow = async (message) => {
    //logMessageWithDetails(message);
    setInfoMessage({ prompt: JSON.stringify(message.log.prompt ?? {}, null, 2), dataUsed: JSON.stringify(message.log.dataUsed ?? {}, null, 2), completion: JSON.stringify(message.log.completion ?? {}, null, 2) });
    setOpen(true);
  };

  // Function to get a detailed description of the variable
  function logMessageWithDetails(variable) {
    const dataType = typeof variable;
    let dataContent;

    try {
      // Attempt to stringify if it's an object/array, otherwise just use the value
      dataContent = dataType === 'object' ? JSON.stringify(variable, null, 2) : variable;
    } catch (e) {
      // Fallback in case of circular references or other stringify issues
      dataContent = "Unable to stringify object.";
    }

    console.log(`Type: ${dataType}`);
    console.log(`Content:`, dataContent); // Using comma allows for better object/array logging
  }

  const handleClose = () => {
    setOpen(false);
  };

  const processMeshData = (m) => {
    setMeshData(m);
    // m.traverse((s) => { if (s.isMesh) console.log(s) });
  };

  useEffect(() => {
    if (!meshData) return;
    let submeshes = [];
    meshData.traverse((s) => { if (s.isMesh) submeshes.push(s); });
    setSubmeshes(submeshes);
  }, [meshData]);

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1 }}>
            Expert: {expert?.name}
          </div>
          <div style={{ flex: 3 }}>
            <TextField
              value={chatName}
              onChange={(e) => handleNameChange(e.target.value)}
              label="Chat Name"
              fullWidth
            />
          </div>
        </Grid>
      </Grid>


      <Grid container spacing={3} style={{ marginTop: '1%' }}>
        {object?.fileURL &&
          <Grid item xs={4}>
            <Paper>
              {object?.fileURL &&
                <ObjectDisplay
                  objectData={object}
                  meshData={processMeshData}
                  currentSubmesh={currentSubmesh}
                // tags={tagList}
                // visibleTags={visibleTags}
                />}
            </Paper>
          </Grid>
        }
        <Grid item xs={object?.fileURL ? 6 : 12}>

          <Grid container spacing={1}>

            <Grid item xs={5}>
              <StyledButton variant="contained" color="secondary" onClick={clearConversation} fullWidth>Clear</StyledButton>
            </Grid>
            <Grid item xs={5}>
              <StyledButton variant="contained" color="primary" onClick={handleDeleteConversation} fullWidth>Delete</StyledButton>
            </Grid>
            <Grid item xs={1}>
              <StyledButton variant="contained" color="primary" onClick={mute} fullWidth>{isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}</StyledButton>
            </Grid>
          </Grid>
          <MessageContainer>
            {messages.map((msg) => (
              <ChatBubble
                key={msg.id}
                message={msg.message}
                isUser={msg.owner === user.uid}
                infoHandle={() => handleInfoShow(msg)}
              />
            ))}
            {textStream && <ChatBubble message={textStream} isUser={false} />}
            <div ref={messagesEndRef} />
          </MessageContainer>
          <ChatInput sendMessage={sendMessage} loading={thinking} />
          <audio ref={audioRef} src={audioSrc}>Your browser does not support the audio element.</audio>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose} scroll="paper" aria-labelledby="info-dialog-title" fullWidth maxWidth="md" value>
        <DialogTitle id="info-dialog-title">Message Log</DialogTitle>

        <DialogContent dividers>
          <Box component="div" bgcolor="primary.main" color="primary.contrastText" p={1} borderRadius={2} mb={2} display="inline-block">
            <Typography variant="h7">PROMPT</Typography>
          </Box>
          <Divider />
          <Typography variant="body1"><pre style={{ whiteSpace: "pre-wrap" }}>{infoMessage.prompt}</pre></Typography>

          <Box component="div" bgcolor="primary.main" color="primary.contrastText" p={1} borderRadius={2} mt={2} mb={2} display="inline-block">
            <Typography variant="h7">DATA USED</Typography>
          </Box>
          <Divider />
          <Typography variant="body1"><pre style={{ whiteSpace: "pre-wrap" }}>{infoMessage.dataUsed}</pre></Typography>

          <Box component="div" bgcolor="primary.main" color="primary.contrastText" p={1} borderRadius={2} mt={2} mb={2} display="inline-block">
            <Typography variant="h7">COMPLETION</Typography>
          </Box>
          <Divider />
          <Typography variant="body1"><pre style={{ whiteSpace: "pre-wrap" }}>{infoMessage.completion}</pre></Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}