import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Checkbox, Radio, Chip, Box } from '@mui/material';
import { doc, addDoc, setDoc, getDoc, arrayUnion, updateDoc, deleteDoc, collection, serverTimestamp, query, where, getDocs, onSnapshot, orderBy } from "firebase/firestore"; 
import { db } from '../firebase';
import AddResourceDialog from './AddResourcesDialog';

export default function ResourceTable({ collectionName, fieldName, header, onSelect, selectionType = 'checkbox', selected, filter = [] }) {
  const { orgId } = useParams();
  const [resources, setResources] = useState();
  const [selection, setSelection] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogResources, setDialogResources] = useState(null);

  const navigate = useNavigate();

  const loadData = () => {
    const expertsCollection = collection(db, collectionName);
    const fileQuery = query(expertsCollection, where('owner', '==', orgId));

    const unsubscribe = onSnapshot(fileQuery, (querySnapshot) => {
      const filePromises = querySnapshot.docs.map(async (doc) => {
        const data = doc.data();
        return { id: doc.id, ...data };
      });

      // Function to check if an object contains a nested property path
      const hasNestedProperty = (obj, path) => {
        return path.split('.').reduce((currentObject, key) => {
          return currentObject && currentObject[key] !== undefined ? currentObject[key] : undefined;
        }, obj) !== undefined;
      };

      // Updated filter logic in the loadData function
      Promise.all(filePromises).then(list => {
        let filteredList = list.filter(resource => 
          !resource.status || ['vectorized', 'ready'].includes(resource.status));

        if (filter.length > 0) {
          // Filter the list based on the nested property paths in the filter array
          filteredList = filteredList.filter(resource => 
            filter.some(filterPath => hasNestedProperty(resource, filterPath)));
        }

        setResources(filteredList);
        setSelection(selected);
      });
      
    });

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = loadData();
    return () => unsubscribe();
  }, [selected]); 

  const handleSelect = (id) => {
    let newSelected = [];
  
    // if (selectionType === 'checkbox') {
      newSelected = selection?.includes(id) ? selection.filter(item => item !== id) : [...selection, id];
    // } else {
    //   newSelected = [id];
    // }
  
    setSelection(newSelected);
    onSelect(newSelected);
  };

  // const handleEdit = (id) => {
  //   navigate(`/organizations/${orgId}/${collectionName}/${id}`);
  // };

  const handleDialogOpen = () => {
    setDialogResources({ resources, fieldName });
    setDialogOpen(true);  // Set dialog open state to true
  };
  
  const handleDialogClose = () => {
    setDialogOpen(false);  // Set dialog open state to false
  };

  const handleAddResource = (selectedResources) => {
    // console.log(selectedResources);
    // Code to add selected resources
    if (JSON.stringify(selectedResources.slice().sort()) !== JSON.stringify(selection.slice().sort())) {
      setSelection(selectedResources);
      onSelect(selectedResources);
    }
    
    setDialogResources(null);  // Close dialog
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleDialogOpen}>{header}</Button>
      {dialogResources && <AddResourceDialog
        resourceData={dialogResources}
        onSelected={handleAddResource}
        initiallySelected={selection}
        open={dialogOpen}
        onClose={handleDialogClose}
      />}
      <Box sx={{ mt: 2 }}>
        {selection && selection.map((resourceId, index) => {
          const resource = resources.find(r => r.id === resourceId);
          return (
            <Chip
              key={index}
              label={resource?.[fieldName]}
              sx={{ mt: 1, mr: 1 }}
              onDelete={() => handleSelect(resourceId)}
              variant="outlined"
            />
          );
        })}
      </Box>
    </>
  );
}
