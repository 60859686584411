import axios from 'axios';
import { auth } from '../firebase';
import { eventManager } from './eventManager';

// COMMON
const DEV_MODE = process.env.NODE_ENV === 'development';
const DEV = DEV_MODE;

// ENDPOINTS
const DEV_BASE = "http://127.0.0.1:5001/dev-immersion-ai-assistant/europe-west1";
const PROD_BASE = "https://europe-west1-dev-immersion-ai-assistant.cloudfunctions.net";
const TARGET_BASE = DEV ? DEV_BASE : PROD_BASE;
const API_VERSION = "v2"

const ENDPOINT_ORGANIZATION = `/core/organization`;
const ENDPOINT_CORE = `/core`;
const ENDPOINT_OPERATIONS = `/operations`;
const ENDPOINT_OPERATIONS_FILE = `/operations/file`;
const ENDPOINT_OPERATIONS_TAG = `/operations/tag`;
const ENDPOINT_OPERATIONS_VECTORS = `/operations/vectors`;
const ENDPOINT_OPERATIONS_OBJECT = `/operations/object`;
const ENDPOINT_COMPLETIONS_CHATGPT = `/completions/chatgpt`;
const ENDPOINT_TTS_OPENAI = `/tts/openai`;
const ENDPOINT_STT_OPENAI = `/stt/openai`;
const ENDPOINT_OPERATIONS_API = `/operations/api`;
const ENDPOINT_CONVERSATIONS_API = `/conversations`;
const ENDPOINT_THREAD_API = `/threads`;
const ENDPOINT_USER = `/core/user`;

// const SAVE_TO_PINECONE = "https://dev-26bcfd9.svc.us-west1-gcp-free.pinecone.io/vectors/upsert";

//=============================================================================================
//  COMMON
//=============================================================================================

const apiClient = axios.create({ baseURL: `${TARGET_BASE}/${API_VERSION}`, });

apiClient.interceptors.request.use(
  async (config) => {
    return config;
  }, (e) => { return Promise.reject(e); }
);

apiClient.interceptors.response.use(
  response => response,
  async (error) => {
    const req = error.config;

    // token refresh if 401, catch generc errors
    if (error.response.status === 401 && !req._retry) {
      req._retry = true;
      console.log("Refreshing token...");
      const user = auth.currentUser;
      if (user) {
        const token = await user.getIdToken(true);
        setDefaultHeaders(token);
        req.headers['Authorization'] = `Bearer ${token}`;
        return apiClient(req);
      }
    } else if (!error.response || error.response.status >= 400) { eventManager.publish('api-error', error); }

    return Promise.reject(error);
  }
);

let userJWTToken = null;
export const setUserApiKey = (key) => {
  userJWTToken = key;
}

export const setDefaultHeaders = (token) => {
  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
    'API-Auth': userJWTToken
  }
  apiClient.defaults.headers.common = { ...apiClient.defaults.headers.common, ...headers };
};

//=============================================================================================
//  USER EDITING
//=============================================================================================

export const addUserToOrganization = async (orgId, email, role) => await apiClient.post(`${ENDPOINT_ORGANIZATION}/${orgId}/user`, { email, role }).catch(e => e);
export const removeUserFromOrganization = async (orgId, userId) => await apiClient.delete(`${ENDPOINT_ORGANIZATION}/${orgId}/user/${userId}`).catch(e => e);
export const resetUserPassword = async (orgId, userId) => await apiClient.get(`${ENDPOINT_ORGANIZATION}/${orgId}/user/${userId}/resetpassword`).catch(e => e);

//=============================================================================================
//  VECTORIZATION
//=============================================================================================

export const processFile = async (file, useRaw) => await apiClient.post(`${ENDPOINT_OPERATIONS_FILE}/vectorize`, { file, isFile: true, useRaw: useRaw }).catch(e => e);
export const processTag = async (tag, useRaw) => await apiClient.post(`${ENDPOINT_OPERATIONS_TAG}/vectorize`, { tag }).catch(e => e);
export const addVectors = async (owner, vdb, namespace) => await apiClient.post(`${ENDPOINT_OPERATIONS_VECTORS}`, { owner, vdb, namespace }).catch(e => e);
export const deleteVectors = async (ownerid, vdb, namespace) => await apiClient.delete(`${ENDPOINT_OPERATIONS_VECTORS}/${ownerid}/${vdb}/${namespace}`).catch(e => e);
export const deleteFileData = async (fileId, vdb, namespace) => await apiClient.delete(`${ENDPOINT_OPERATIONS_FILE}/${fileId}`).catch(e => e);
export const deleteNamespace = async (vdb, namespace) => await apiClient.delete(`${ENDPOINT_OPERATIONS_VECTORS}/${vdb}/${namespace}`).catch(e => e);

//=============================================================================================
//  CONVERSATIONS
//=============================================================================================

export const createThread = async (data) => await apiClient.post(`${ENDPOINT_THREAD_API}`, data).catch(e => e);

//=============================================================================================
//  OBJECTS
//=============================================================================================

export const deleteObjectData = async (id) => await apiClient.delete(`${ENDPOINT_OPERATIONS_OBJECT}/${id}`).catch(e => e);

// core resources this is dumb

export const getCoreResource = async (type, id) => await apiClient.get(`${ENDPOINT_CORE}/${type}/${id}`).catch(e => e);
export const setCoreResource = async (type, data) => await apiClient.post(`${ENDPOINT_CORE}/${type}`, { data }).catch(e => e);
export const updateCoreResource = async (type, data, id) => await apiClient.post(`${ENDPOINT_CORE}/${type}/${id}`, { data }).catch(e => e);
export const deleteResource = async (type, id) => await apiClient.delete(`${ENDPOINT_OPERATIONS}/${type}/${id}`).catch(e => e);

// operations resources

export const getResource = async (type, id) => await apiClient.get(`${ENDPOINT_OPERATIONS}/${type}/${id}`).catch(e => e);
export const getResources = async (type, owner, filter = "") => await apiClient.get(`${ENDPOINT_OPERATIONS}/${type}/${owner}/list${filter}`).catch(e => e);
export const findResources = async (type, field, value = "") => await apiClient.get(`${ENDPOINT_OPERATIONS}/${type}/find-by/${field}/${value}`).catch(e => e);
export const getResourcesURI = async (uri) => await apiClient.get(`/${uri}`).catch(e => e);
export const setResource = async (type, data) => await apiClient.post(`${ENDPOINT_OPERATIONS}/${type}`, { data }).catch(e => e);
export const updateResource = async (type, data, id) => await apiClient.post(`${ENDPOINT_OPERATIONS}/${type}/${id}`, { data }).catch(e => e);

export const addStorageResource = async (file, owner, type) => {
  const formData = new FormData();
  formData.append(type, file);
  formData.append('owner', owner);
  return await apiClient.post(`${ENDPOINT_OPERATIONS}/${type}`, formData).catch(e => e);
};

// other

export const saveToOpenAI = async (type, id, data) => await apiClient.post(`${ENDPOINT_OPERATIONS}/${type}/${id}/extref/openai`, { data }).catch(e => e);
export const deleteFromOpenAI = async (type, id) => await apiClient.delete(`${ENDPOINT_OPERATIONS}/${type}/${id}/extref/openai`).catch(e => e);

export const findConversations = async (field, value = "") => await apiClient.get(`/conversations/find-by/${field}/${value}`).catch(e => e);

export const updateOrganization = async (data, id) => await apiClient.post(`/core/organization/${id}`, data).catch(e => e);

//=============================================================================================
//  CHAT
//=============================================================================================

export const getCompletionStream = async (input, expert, onMessageCallback, onStreamEndCallback) => {
  const url = new URL(`${TARGET_BASE}/${API_VERSION}${ENDPOINT_COMPLETIONS_CHATGPT}/stream`);
  url.searchParams.append('input', JSON.stringify(input));
  url.searchParams.append('expert', JSON.stringify(expert));
  url.searchParams.append('token', await auth.currentUser.getIdToken());

  const eventSource = new EventSource(url.toString());
  eventSource.onmessage = event => onMessageCallback(JSON.parse(event.data));
  eventSource.onerror = error => {
    console.error('EventSource failed:', error);
    eventSource.close();
    onStreamEndCallback();
  };

  return () => eventSource.close();
};

export const addMessageToConversation = async (id, message = '', isUser, data = {}) => await apiClient.post(`${ENDPOINT_CONVERSATIONS_API}/${id}/message`, { message, isUser, data }).catch(e => e);
export const addMessageToThread = async (id, data) => await apiClient.post(`${ENDPOINT_THREAD_API}/${id}`, data).catch(e => e);
export const clearMessages = async (id) => await apiClient.get(`${ENDPOINT_CONVERSATIONS_API}/${id}/clear`).catch(e => e);
export const deleteConversation = async (id) => await apiClient.delete(`${ENDPOINT_CONVERSATIONS_API}/${id}`).catch(e => e);

export const sendMessageToConversation = async (id, message, onMessageCallback, onStreamEndCallback) => {
  const token = await auth.currentUser.getIdToken();

  const url = new URL(`${TARGET_BASE}/${API_VERSION}${ENDPOINT_CONVERSATIONS_API}/${id}/stream`);

  url.searchParams.append('message', JSON.stringify(message));
  url.searchParams.append('token', token);
  url.searchParams.append('apiauth', userJWTToken);

  const eventSource = new EventSource(url.toString());

  eventSource.onmessage = event => onMessageCallback(JSON.parse(event.data));
  eventSource.onerror = error => {
    console.error('EventSource failed:', error);
    eventSource.close();
    onStreamEndCallback();
  };

  return () => eventSource.close();
};


//=============================================================================================
//  TTS
//=============================================================================================

export const getSpeech = async (data) => await apiClient.post(`${ENDPOINT_TTS_OPENAI}/`, data, {
  responseType: 'blob'
}).then(response => window.URL.createObjectURL(new Blob([response.data]))).catch(e => e);


//=============================================================================================
//  STT
//=============================================================================================

export const transcribeAudio = async (audio) => await apiClient.post(`${ENDPOINT_STT_OPENAI}/`, audio).catch(e => e);

//=============================================================================================
//  API
//=============================================================================================
export const setAppPassword = async (password, email = '') => await apiClient.post(`${ENDPOINT_USER}/${auth.currentUser.uid}/apppass`, { email: email ? email : auth.currentUser.email, password }).catch(e => e);
export const deleteAppPassword = async (id) => await apiClient.delete(`${ENDPOINT_USER}/${auth.currentUser.uid}/apppass`).catch(e => e);

export const getOpenAIKey = async (id) => await apiClient.get(`${ENDPOINT_OPERATIONS_API}/key/openai/${id}`).catch(e => e);
export const setOpenAIKey = async (id, key) => await apiClient.post(`${ENDPOINT_OPERATIONS_API}/key/openai/${id}`, { key }).catch(e => e);
export const deleteOpenAIKey = async (id) => await apiClient.delete(`${ENDPOINT_OPERATIONS_API}/key/openai/${id}`).catch(e => e);